.home-header{
    display: flex;
    align-items: center;
    margin: 55px 0;
    padding: 10px 0;
    gap: 20px;
}

.home-header-title-images-container{
    max-width: 515px;
    padding: 35px;
    border-radius: 10px;
    border: 1px solid #26262650;
    position: relative;
}
.home-header-title-container{
    max-width: 725px;
    padding-right: 20px;
}

.home-header-title{
    font-size: 48px;
    font-weight: 500;
    padding-bottom: 15px;
}

.home-header-subtitle{
    font-size: 18px;
    line-height: 150%;
    font-weight: 400;
    padding-bottom: 20px;
}

.home-header-title-images-container-whitebg{
    background-color: #fff;
    border-radius: 10px;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;
}

.home-header-title-images-container-ico{
    position: absolute;
    top: -120px;
    right: -200px;
    z-index: -4;
}

.home-header-title-images-container-ico2{
    position: absolute;
    top: -50px;
    left: -50px;
    width: 190px;
}

.home-header-title-images-container-ico3{
    position: absolute;
    width: 370px;
    bottom: -80px;
    right: -100px;
}


.home-header-title-images-container-title{
    font-weight: 600;
    padding-bottom: 17px;
}

.home-header-title-download-container-title{
    font-weight: 600;
}

.home-header-title-download-container{
    padding-top: 27px;
}

.home-header-title-download-btn{
    max-width: 380px;
}

.home-header-title-images-container-card{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-header-title-images-container-card-item{
    margin: 0;
}

.pc1{
    grid-area: pc1;
}

.pc1_2{
    grid-area: pc1_2;
}

.pc2{
    grid-area: pc2;
}

.pc3{
    grid-area: pc3;
}

.pctext{
    grid-area: pctext;
}

.home-header-title-images-container-grid{
    display: grid;
    gap: 15px;
    max-width: 100px;
    grid-template-areas: 
    'pc1 pc1_2 pctext'
    'pc2 pc2 pc3'
    ;
    height: 550px;
    transform: scale(.7) translateY(-120px);
}

.pcblock{
    max-width: 400px;
}

.home-header-title-image{
    height: 100%;
    border-radius: 15px;
    box-shadow: 0 0 5px #00000040;
}

.pctext{
    height: 300px;
}

.home-header-pdfdoclink{
    padding: 20px 0;
    padding-bottom: 0;
    font-size: 18px;
    color: #57C769;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.home-header-pdfdoclink a{
    text-decoration: underline;
}

.link_ico{
    padding-left: 5px;
}

.home-header-pdfdoclink{
}

.home-header-start-block{
    display: flex;
    gap: 10px;
    padding-top: 10px;
}
.home-header-discount-block{
    max-width: 270px;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    /* width: 100%;
    height: 100%; */
    /* align-items: center; */
    /* height: 100%; */
    object-fit: cover;
  }
/*   
  .swiper-slide {
    width: 80%;
  }
  
  .swiper-slide:nth-child(2n) {
    width: 60%;
  }
  
  .swiper-slide:nth-child(3n) {
    width: 40%;
  }
   */

  .home-header-title-images-swiper{
    width: 100%;
  }

.home-header-title-images-swiper{
}

.swiper-antd-image{
}

@media only screen and (max-width: 1550px) {
    
    .home-header-title-images-container{
        max-width: 415px;
        padding: 35px;
        border-radius: 10px;
        border: 1px solid #26262650;
        position: relative;
    }
    .home-header-title-container{
        max-width: 625px;
        padding-right: 120px;
    }

    .home-header-title{
        font-size: 32px;
        font-weight: 500;
        padding-bottom: 15px;
    }
    
    .home-header-subtitle{
        font-size: 18px;
        line-height: 150%;
        font-weight: 400;
        padding-bottom: 50px;
    }
    .home-header{
        justify-content: start;
    }
    .home-header-title-images-container-ico{
        max-width: 220px;
        top: -50px;
        right: -100px;
    }
    .home-header-title-images-container-grid{
        height: 450px;
        transform: scale(.5) translateY(-200px);
    }
}
@media only screen and (max-width: 1290px) {
    .home-header{
        padding: 20px 20px;
        margin: 0 auto;
    }
    .home-header-title-images-container{
        max-width: 315px;
        padding: 35px;
        border-radius: 10px;
        border: 1px solid #26262650;
        position: relative;
    }
    .home-header-title-container{
        max-width: 565px;
        padding-right: 120px;
    }

    .pricechecker-card{
        max-width: 230px;
    }

    .pricechecker-card-company{
        font-size: 20px!important;
        padding-bottom: 5px;
    }
    .pricechecker-card-product{
        font-size: 14px!important;
    }
    .pricechecker-card-price{
        font-size: 14px!important;
    }
    .home-header-title-images-container-ico2{
        position: absolute;
        top: -50px;
        left: -50px;
        width: 180px;
    }
    
    .home-header-title-images-container-ico3{
        position: absolute;
        width: 300px;
        bottom: -80px;
        right: -100px;
    }
    .home-header-pdfdoclink{
        flex-direction: column;
        align-items: start;
    }
}
@media only screen and (max-width: 1080px) {
    .home-header-title-images-container-ico2{
        position: absolute;
        top: -50px;
        left: -50px;
        width: 190px;
    }

    .home-header-title-images-container-ico3{
        position: absolute;
        width: 370px;
        bottom: -80px;
        right: -100px;
    }
    .home-header{
        flex-direction: column-reverse;
        gap: 100px;
        align-items: center;
        display: flex;
        justify-content: center!important;
    }
    .home-header-title-images-container-grid{
        max-width: inherit;
    }
    .pc3{
        display: none;
    }
    .home-header-title-images-container{
        max-width: 415px;
        padding: 35px;
        border-radius: 10px;
        border: 1px solid #26262650;
        position: relative;
    }
    .home-header-title-container{
        max-width: 825px;
        padding-top: 30px;
        padding-right: 0;
        text-align: center;
    }
    
    .pricechecker-card-company{
        font-size: inherit;
        padding-bottom: 5px;
    }
    .pricechecker-card-product{
        font-size: 18px!important;
    }
    .pricechecker-card-price{
        font-size: 20px!important;
    }
    .pricechecker-card{
        max-width: inherit;
    }
    .home-header-title-images-container-ico{
        max-width: 300px;
        top: -100px;
        right: -100px;
    }
    .home-header-pdfdoclink{
        align-items: center;
    }
    .home-header-discount-block{
        margin: 0 auto;
    }
    .home-header-start-block{
        justify-content: center;
    }
}
@media only screen and (max-width: 720px) {
    
    .home-header-title-images-container{
        max-width: 355px;
    }
    .home-header{
        gap: 50px;
        padding: 50px 10px;
    }
}
@media only screen and (max-width: 545px) {
    .home-header{
    }
    .home-header-title-images-container-grid{
        transform: scale(.4);
    }
    .home-header-title-images-container{
        max-width: 335px;
    }
    .home-header-title-images-container-ico2{
        position: absolute;
        top: -30px;
        left: -50px;
        width: 150px;
    }
    
    .home-header-title-images-container-ico3{
        position: absolute;
        width: 250px;
        bottom: -40px;
        right: -30px;
    }
    .pricechecker-card-company{
        font-size: 20px!important;
        padding-bottom: 5px;
    }
    .pricechecker-card-product{
        font-size: 14px!important;
    }
    .pricechecker-card-price{
        font-size: 14px!important;
    }
    .pricechecker-card{
        max-width: 230px;
    }
    .home-header-title-images-container-ico{
        max-width: 200px;
        top: -60px;
        right: -40px;
    }
    .home-header-title-images-container-ico{
        max-width: 200px;
        top: -60px;
        right: -0px;
    }
    .home-header-title-images-container-ico2{
        position: absolute;
        top: -30px;
        left: -0px;
        width: 150px;
    }
    
    .home-header-title-images-container-ico3{
        position: absolute;
        width: 250px;
        bottom: -60px;
        right: -0px;
    }
    .home-header-start-block{
        flex-direction: column;
        align-items: center;
    }
    .home-header-title-download-btn{
        max-width: none;
    }
}
@media only screen and (max-width: 390px) {
    .home-header-title-images-container-grid{
        transform: scale(.3);
    }
}
@media only screen and (max-width: 320px) {
    .home-header-title-images-container{
        max-width: 265px;
    }
}
@media only screen and (max-width: 260px) {}