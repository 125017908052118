.bottomtag{
    margin-bottom: 25px;
}
.bottomtag-container{
    padding: 30px;
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(87,199,105,1) 0%, rgba(116,209,131,0.7595413165266106) 38%, rgba(87,199,105,1) 100%);
    
}
.bottomtag-container-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bottomtag-container-getaccess{
    padding-top: 40px;
}
.bottomtag-container-getaccess h3{
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
}

.bottomtag-getaccess-btn{
    height: 55px;
    width: 200px;
    text-align: center;
    font-size: 20px;
}

.bottomtag-titles{
    max-width: 1100px;
}

.bottomtag-titles h3{
    font-size: 40px;
    font-weight: 400;
    color: #fff;
}

.bottomtag-titles p{
    font-size: 18px;
    font-weight: 300;
    color: #fff
}

.bottomtag-btn{
    width: 200px;
    height: 63px;
    border-radius: 100px;
    border: 0;
    background-color: #242B58;
    color: #fff;
    font-size: 20px;
}


@media only screen and (max-width: 1550px) {
    .bottomtag-container-title{
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    .bottomtag-container-getaccess{
        padding-top: 20px;
    }
}
@media only screen and (max-width: 1310px) {
    .bottomtag-title{
        padding-bottom: 10px;
    }
    .bottomtag{
        margin: 0 10px;
        margin-bottom: 125px;
    }
}
@media only screen and (max-width: 770px) {
    .bottomtag-container-getaccess-form{
        max-width: 200px!important;
    }
    .bottomtag-container-getaccess-form-input{
        width: 300px!important;
    }
}
@media only screen and (max-width: 610px) {
    .bottomtag-container-getaccess-form{
        max-width: 140px!important;
    }
    .bottomtag-container-getaccess-form-input{
        width: 310px!important;
    }
    .bottomtag-container-getaccess-form{
        flex-direction: column;
    }
    .bottomtag-container-getaccess-form-input{
        margin-left: 0!important;
    }
}
@media only screen and (max-width: 510px) {
    
    .bottomtag-container-getaccess-form-input{
        width: 260px!important;
    }
    .bottomtag-container{
        padding: 40px;
    }
    .bottomtag-title{
        font-size: 30px!important;
    }
}
@media only screen and (max-width: 350px) {
    .bottomtag-container-getaccess-form-input{
        width: 190px!important;
    }
}
@media only screen and (max-width: 280px) {
    .bottomtag-title{
        font-size: 25px!important;
    }
    .bottomtag-container-getaccess-form-input{
        width: 150px!important;
    }
    .bottomtag-btn{
        width: 153px;
        height: 43px;
    }
    .bottomtag-container-getaccess-form-input{
        height: 43px!important;
        font-size: 15px!important;
    }
    .bottomtag-getaccess-btn{
        width: 153px;
        height: 43px;
    }
}