.partner_section{
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    padding: 120px 110px;
}

.partner_section h2{
    font-weight: 400;
    font-size: 2em;
}

.partner_section h1{
    font-weight: 500;
    font-size: 2.7em;
}

.partner_logos{
    max-width: 700px;
    margin-top: 60px;
}

.partner_logos img{    
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    max-width: 150px;
    margin-right: 50px;
    margin-bottom: 10px;
    transition: filter .4s ease-out;
}

.partner_logos img:hover{
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
}

.partner_btn{
    border: 1px solid #000000;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 50px;
}


@media (max-width: 1615px){
    .partner_section h1{
        font-size: 2.1em;
    }
}

@media (max-width: 1235px){
    .partner_section h1{
        font-size: 1.9em;
    }
    .partner_logos img{
        max-width: 130px;   
        margin-right: 20px;
    }
}
@media (max-width: 1096px){
    .partners_title{
        margin-bottom: 20px;
    }
    .partner_logos img{
        max-width: 130px;   
        margin-right: 20px;
    }
    .partner_section{
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 980px){
    .partner_section{
        padding: 120px 80px;
    }
}
@media (max-width: 700px){
    .partner_logos img{
        max-width: 100px;   
        margin-right: 10px;
    }
}
@media (max-width: 585px){
    .partner_section{
        padding: 120px 40px;
    }
}
@media (max-width: 425px){
    .partner_logos img{
        max-width: 80px;
    }
}
@media (max-width: 405px){
    .partner_section{
        padding: 120px 40px;
    }
    .partner_logos img{
        max-width: 60px;  
    }
    .partner_section h2{
        font-size: 1.7em;
    }
    .partner_section h1{
        font-size: 1.7em;
    }
}