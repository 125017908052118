.pricechecker-main{
    overflow-x: hidden;
}

.app-container{
    max-width: 1795px;
    margin: 0 auto;
    padding: 0 20px;
}
.pricechecker-filled-button{
    background-color: #57C769;
    color: #fff;
    border-radius: 100px;
}
.pricechecker-filled-button:hover{
    background-color: #fff;
    color: #000!important;
    border-color: #000!important;
}

.pricechecker-card{
    display: flex;
    align-items: center;
    border: 1px solid #26262650;
    border-radius: 10px;
}

.pricchecker-card-icon{
    background-color: #57C769;
    max-width: 38px;
    height: 38px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    margin-right: 10px;
}

.pricechecker-card-company{
    font-size: 16px;
    font-weight: 400;
}

.pricchecker-card-titles{
    width: 100%;
}

.pricechecker-card-subtitles{
    display: flex;
    justify-content: space-between;
    font-size: 17px;
}
.pricechecker-card-price{
    font-size: 21px;
}

.app-logo{
    max-width: 45px;
    margin-right: 10px;
}


@media only screen and (max-width: 1550px) {
    .app-container{
        max-width: 1455px;
        margin: 0 auto;
        padding: 0 20px;
    }
}
@media only screen and (max-width: 720px) {
    .app-container{
        max-width: 700px;
        margin: 0 auto;
        padding: 0 5px;
    }
}
@media only screen and (max-width: 510px) {}
@media only screen and (max-width: 320px) {}
@media only screen and (max-width: 260px) {}