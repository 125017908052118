.navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 0 80px;
    font-size: 20px;
    font-weight: 500;
    background-color: white;
    position: fixed;
    z-index: 2;
}

.anchors *{
    font-size: 20px;
    font-weight: 500;
}

.burger{
    display: none;
}

.burger_side{
    display: flex;
    align-items: center;
}

.lang{
    margin-left: 10px;
    padding: 2px;
    display: block;
    font-size: 16px;
}

@media (max-width: 1096px){
    .anchors{
        display: none;
    }
    .burger{
        display: block;
    }
    
    .burger_side{
        width: 300px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .burger{
        font-size: 2em;
    }
    .nav_burger{
        position: relative;
    }
    .mob_anchor *{
        font-size: 50px;
        font-weight: 500;
    }
    .burger_contacts li{
        padding-bottom: 10px;
    }
    .burger_contacts{
        position: absolute;
        bottom: 50px;
        right: 50px;
        list-style-type: none;
        font-size: 2em;
        font-weight: 500;
    }
}
@media (max-width: 796px){  
    .lang{
        margin-right: 10px;
    } 
    .burger_side{
        width: 200px;
    }
}
@media (max-width: 596px){   
    .burger_side{
        width: 200px;
    }
    .navigation{
        padding: 0 20px;
    }
}
@media (max-width: 420px){
    .burger_side{
        width: 130px;
    }
    .burger_side a{
        font-size: 1em;
    }
    .burger{
        font-size: 1.4em;
    }
    .mob_anchor *{
        font-size: 30px;
    }
}