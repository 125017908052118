*{
    font-family: "Lexend", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.pricechecker-filled-button{
    background-color: #57C769;
    color: #fff;
    border-radius: 100px;
}
.pricechecker-filled-button:hover{
    color: #57C769!important;
    border-color: #9bffab!important;
}

.pricechecker-card{
    display: flex;
    align-items: center;
    border: 1px solid #26262650;
    border-radius: 10px;
}

.pricchecker-card-icon{
    background-color: #57C769;
    max-width: 38px;
    height: 38px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    margin-right: 10px;
}

.pricechecker-card-company{
    font-size: 16px;
    font-weight: 400;
}

.pricchecker-card-titles{
    width: 100%;
}

.pricechecker-card-subtitles{
    display: flex;
    justify-content: space-between;
    font-size: 17px;
}
.pricechecker-card-price{
    font-size: 21px;
}

.app-logo{
    max-width: 45px;
    margin-right: 10px;
}

.discount-timer{
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 30px;
    max-width: 300px;
}

.discount-block{
    position: relative;
}

.main-price{
    position: absolute;
    left: 0;
    top: -20px;
    font-size: 20px;
    font-weight: 500;
    color: #586268;
}

.main-price::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #000;
    left: 1px;
    top: 50%;
    transform: translateY(-50%) rotate(-20deg);

}

.discount-price{
    border-radius: 10px;
    color: #EC191E;
    font-size: 30px;
    font-weight: 600;
    margin-right: 15px;
}

.discount-timer-block{
    border-radius: 10px;
    display: flex;
    font-size: 24px;
    height: 20px;
    color: #26262690;
}
