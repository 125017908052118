.features{
    margin-bottom: 95px;
}
.features-title{
    margin-bottom: 80px;
}

.features-title h2{
    font-size: 48px;
    padding-bottom: 14px;
    font-weight: 500;
}

.features-title p{
    font-size: 18px;
    max-width: 1295px;
    font-weight: 300;
}

.features-features-container{
    display: flex;
    justify-content: center;
    gap: 30px;
    height: 550px;
}

.features-features-tabs-btns{
    display: flex;
    flex-direction: column;
    padding: 50px;
    row-gap: 24px;
}

.features-features-tabs-btn{
    text-align: center;
    font-size: 18px;
    width: 210px;
    padding: 18px 24px;
    border: 1px solid #26262670;
    border-radius: 100px;
    cursor: pointer;
}

.features-features-tabs-btn-selected{
    background-color: #57C769;
    color: #fff;
}

.features-features{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.features-feature-item{
    max-width: 610px;
    padding: 50px;
    border: 1px solid #26262680;
    border-radius: 12px;
}


.features-feature-item h3{
    font-size: 22px;
    padding-bottom: 30px;
    font-weight: 400;
}


.features-feature-item p{
    font-size: 18px;
    font-weight: 300;
    color: #131212;
}



@media only screen and (max-width: 1590px) {
    .features{
        margin: 0 auto;
    }
    .features-features{
        height: 570px;
        overflow-y: scroll;
        padding: 20px 0;
        border-top: 1px solid #13121250;
        border-bottom: 1px solid #13121250;
    }
    .features-feature-item{
        max-width: 510px;
    }
}
@media only screen and (max-width: 1405px) {
    .features-feature-item{
        max-width: 410px;
    }
}
@media only screen and (max-width: 1290px) {
    .features{
        max-width: 1205px!important;
        margin: 0 auto;
    }
    .features-feature-item{
        max-width: 610px;
    }
}
@media only screen and (max-width: 1080px) {
    .features{
        max-width: 825px!important;
        margin: 0 auto;
    }
}
@media only screen and (max-width: 850px) {
    .features-title{
        text-align: center;
    }
    .features-features-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .features-features-tabs-btns{
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 0px;
        row-gap: 24px;
    }
}
@media only screen and (max-width: 720px) {
    .features-features-container{
        align-items: inherit;
    }
    .features-features-tabs-btns{
        flex-wrap: nowrap;
        height: 100px;
        overflow-y: scroll;
        border-bottom: 1px solid #13121220;
    }
    .features-features-tabs-btn{
        width: 210px!important;
        height: 60px;
        flex-shrink: 0;
    }
    .features-title h2{
        font-size: 35px;
    }
}
@media only screen and (max-width: 320px) {}
@media only screen and (max-width: 260px) {}