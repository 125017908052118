
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 50px 0;
    padding: 0 80px;
  }
  
  .text {
    font-size: 1em;
    letter-spacing: -.01em;
    line-height: 100%;
    margin: 0;
    
    width: 100%;
    color: #000;
    background: linear-gradient(to right, #b6b6b6, #b6b6b6) no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-size: 0%;
    transition: background-size cubic-bezier(.1,.5,.5,1) 0.5s;
    
    padding: 20px 0;
    border-bottom: 1px solid #2F2B28;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
  }
  
  .text span {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    color: #fff;
    
    clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    transform-origin: center;
    transition: all cubic-bezier(.1,.5,.5,1) 0.4s;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .text:hover > span {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  

  
@media (max-width: 1030px){
  .text{
    font-size: 2em;
  }
}  
@media (max-width: 768px){
  .text{
    font-size: 1.6em;
  }
  .container {
    padding: 0 20px;
  }
}