.header{
    height: 805px;
    margin: 0 40px;
    border-radius: 40px;
    position: relative;
    color: #fff;
    overflow: hidden;
    background-color: #000;
    background-repeat: no-repeat;
    background-position: 50% 30%;
}


.videoBackground{
    width: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.videoBackground video{
    width: 100%;
}

.header_title{
    position: absolute;
    bottom: 70px;
    padding: 0 40px;
}

.header_title h3{
    font-size: 1.5em;
    font-weight: 400;
}

.header_title h1{
    font-size: 5em;
    line-height: 78px;
    font-weight: 400;
    margin-bottom: 35px;
}

.to_portfolio{
    padding: 10px 20px;
    color: #000;
    border: 1px solid #fff;
    background-color: #fff;
    display: inline-block;
    border-radius: 5px;
    font-weight: 500;
    transition: background-color .4s ease-in;
}

.to_portfolio:hover{
    background-color: #f3f3f3dc;
    cursor: pointer;
}

@media (max-width: 1530px){
    .header_title h1{
        font-size: 3em!important;
        line-height: 50px;
    }
    .header{
        height: 465px;
        background-position-y: -50%;
    }

    .header_title{
        position: absolute;
        bottom: 40px;
        padding: 0 40px;
    }
    .videoBackground{
        width: 40%;
        position: absolute;
        left: 50%;
        top: -5%;
        transform: translate(-50%);
    }
}


@media (max-width: 1375px){
    .header_title h1{
        font-size: 3.2em;
    }
}
@media (max-width: 1096px){
    .header_title h1{
        font-size: 3em;
    }
    .header{
        background-position-y: -0%;
        background-size: 50%;
    }
}
@media (max-width: 980px){
    .videoBackground{
        width: 60%;
    }
    .header_title h1{
        font-size: 2em!important;
        line-height: 47px;
    }
}
@media (max-width: 700px){
    .header{
        margin: 0 1em;
    }
    .header_title h3{
        font-size: 1.3em;
    }
    .header_title h1{
        font-size: 1.6em;
        font-weight: 500;
        line-height: 40px;
    }
}
@media (max-width: 585px){
    .header{
        height: 565px;
        background-position-y: 10%;
        background-size: 80%;
    }
    .videoBackground{
        width: 90%;
        top: 5%;
    }
    .header_title h1{
        font-size: 1.7em!important;
        line-height: 30px;
    }
}