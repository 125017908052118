
.home_video_doc_title{
    margin-bottom: 80px;
}

.home_video_doc_title h2{
    font-size: 48px;
    padding-bottom: 14px;
    font-weight: 500;
}

.home_video_doc_title p{
    font-size: 18px;
    max-width: 1295px;
    font-weight: 300;
}

.home_video_block{
}

.home_video_element{
    max-width: 1280px;
    height: 720px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px #000000;
}

@media only screen and (max-width: 1590px) {
    .home_video_doc_section{
        margin: 0 auto;
    }
}
@media only screen and (max-width: 1290px) {
    .home_video_doc_section{
        margin: 0 auto;
    }
    .home_video_element{
        max-width: 880px;
        height: 495px;
    }
}
@media only screen and (max-width: 1080px) {
    .home_video_doc_section{
        max-width: 825px!important;
        margin: 0 auto;
    }
    .home_video_element{
        height: 464px;
    }
}
@media only screen and (max-width: 850px) {
    .home_video_doc_title{
        text-align: center;
    }
        
    .home_video_element{
        height: inherit;
    }
}
@media only screen and (max-width: 720px) {
    .home_video_doc_title h2{
        font-size: 35px;
    }
    .home_video_element{
        box-shadow: 0 0 10px #00000000;
    }
}
@media only screen and (max-width: 320px) {}
@media only screen and (max-width: 260px) {}