.footer{
    background-color: black;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-bottom: 100px;
    padding-top: 80px;
}

.footer_contact{
    color: #fff;
    padding: 70px 80px;
}

.footer_contact h1{
    font-size: 5em;
    font-weight: 500;

}
.footer_contact p{
    font-size: 1.6em;
}

.footer_btns{
    display: flex;
    margin-top: 50px;
}

.footer_btns div{
    margin-right: 20px;
    border: 1px solid #fff;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1.4em;
    cursor: pointer;
}

.footer_links{
    color: #fff;
    border-top: 1px solid #fff;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    padding: 100px 0;
}


.footer_links ul{
    display: flex;
    justify-content: space-between;
    list-style: none;
    font-size: 1.1em;
    font-weight: 500;
    margin-top: 100px;
    width: 800px;
}

.footer_links ul a{
    display: block;
    color: #fff;
    text-decoration: none;
}
.footer_links ul li *{
    padding-bottom: 5px;
}
.list_loc a{
    text-decoration: underline!important;
}
.list_nav a:hover{
    color: #ffffff60;
}
@media (max-width: 1460px){
    .footer_links ul{
        width: 545px;
    }
}

@media (max-width: 1375px){
    .map iframe{
        max-width: 400px;
    }
}
@media (max-width: 1096px){
    .footer_links ul{
        flex-direction: column;
        margin-top: 0;
        max-width: 300px;
    }
    
    .footer_links ul li{
        margin-bottom: 15px;
    }
}
@media (max-width: 980px){
    .footer_links ul{
        max-width: 200px;
    }
}
@media (max-width: 700px){
    .footer_links ul{
        margin-top: 60px;
        max-width: 400px;
    }
    .footer_links ul li{
        font-size: 1.7em;
    }
    .footer_links{
        flex-direction: column-reverse;
        align-items: center;
    }
}
@media (max-width: 525px){
    .footer_contact{
        padding: 70px 40px;
    }
    .footer_contact h1{
        font-size: 2.4em;
    }
    .footer_btns{
        font-size: .6em;
    }
}
@media (max-width: 525px){
    .map iframe{
        max-width: 300px;
        height: 300px;
        border-radius: 15px;
    }
    .footer_links ul{
        max-width: 300px;
    }
}
@media (max-width: 365px){
    .footer_btns{
        font-size: .5em;
    }
}