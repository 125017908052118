.portfolio_section{
    background-color: #000;
    color: #fff;
    padding: 200px 160px;
}
.portfolio_title{
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin-bottom: 50px;
}

.portfolio_left_title h2{
    font-size: 2em;
    font-weight: 400;
}

.portfolio_right_title h1{
    font-size: 3.2em;
    line-height: 40px;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 45px;
}

.certificates{
    display: flex;
}

.certificates img{
    max-height: 40px;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    margin-right: 40px;
}

/* CARD */

.portfolio_card{
    width: 100%;
    height: 735px;
    position: relative;
    border-radius: 14px;
}

.portfolio_card img{
    position: absolute;
    width: 60%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.card_hover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .5s ease-out;
    cursor: pointer;
}

.brand_logo{
    position: absolute!important;
    top: 40px!important;
    left: 120px!important;
    max-width: 150px!important;
}

.brand_logo img{
    width: 100%;
}

.card_info{
    position: absolute;
    bottom: 40px;
    left: 40px; 
}

.portfolio_card:hover .card_hover{
    opacity: 1;
}

.cards{
    display: flex;
    flex-direction: column;
}

.cards a{
    margin-bottom: 30px;
}


@media (max-width: 1340px){

    .portfolio_card{
        height: 500px;
    }
}
@media (max-width: 1096px){
    .portfolio_right_title{
        font-size: .8em;
    }
    .portfolio_card{
        height: 300px;
    }
}
@media (max-width: 980px){
    .portfolio_title{
        display: flex;
        flex-direction: column;
    }
    .portfolio_right_title{
        margin-top: 20px;
        font-size: .7em;
    }
    .cards{
        display: flex;
        flex-direction: column;
    }
    .portfolio_section{
        padding: 200px 80px;
    }
}
@media (max-width: 700px){
    .portfolio_card{
        height: 200px;
    }
}
@media (max-width: 585px){
    .portfolio_card{
        height: 300px;
    }
    .portfolio_section{
        padding: 200px 30px;
    }
}