body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Montserrat', sans-serif;
}

.ant-anchor-wrapper-horizontal::before {
  border-bottom: 0;
}
.ant-anchor-link-active>.ant-anchor-link-title-active{
  color: #808080!important;
  font-weight: 600;
}

.ant-anchor-ink-visible{
  background-color: #808080!important; 
}

.ant-anchor-ink{
  background-color: #808080!important; 
}