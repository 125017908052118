.partners{
    position: relative;
}
.partners::before{
    content: '';
    position: absolute;
    background: linear-gradient(90deg, rgba(255,255,255,1) 33%, rgba(255,255,255,0.7959558823529411) 65%, rgba(0,212,255,0) 100%);
    height: 100%;
    width: 200px;
    left: 0;
    z-index: 1;
}
.partners::after{
    content: '';
    position: absolute;
    background: linear-gradient(90deg, rgba(0,212,255,0) 0%, rgba(255,255,255,0.7959558823529411) 54%, rgba(255,255,255,1) 100%);
    height: 100%;
    width: 200px;
    right: 0;
    z-index: 1;
}
section {
    margin: 20px 0;
    padding: 40px 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.logo img{
    width: 100%;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    opacity: .7;
}
 .scroll {
	 display: flex;
	 background: white;
	 height: 40px;
}
 .scroll .text {
	 white-space: nowrap;
	 text-transform: uppercase;
	 animation: scroll 120s linear infinite;
}
 .scroll .text:nth-child(2) {
	 animation: scroll2 120s linear infinite;
	 animation-delay: -60s;
}
 @keyframes scroll {
	 from {
		 transform: translateX(100%);
	}
	 to {
		 transform: translateX(-100%);
	}
}
 @keyframes scroll2 {
	 from {
		 transform: translateX(0%);
	}
	 to {
		 transform: translateX(-200%);
	}
}
 .logo-wall {
	 display: flex;
}
 .logo-wall .logo-wrapper {
	 display: flex;
	 animation: scroll 120s linear infinite;
}
 .logo-wall .logo-wrapper:nth-child(2) {
	 animation: scroll2 120s linear infinite;
	 animation-delay: -60s;
}
 .logo-wall .logo-wrapper .logo {
	 width: 200px;
     padding: 15px 20px;
	 height: 100px;
	 margin: 0 40px;
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 