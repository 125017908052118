.footer{
    padding: 100px 162px 50px 162px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
}

.footer-title{
    display: flex;
    align-items: center;
}

.footer-contacts{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px 0;
}

.footer-contacts-item{
    padding-right: 20px;
    display: flex;
    align-items: center;
}

.footer-contacts-item-icon{
    font-size: 18px;
    margin-right: 6px;
    color: #57C769;
}

.footer-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 100px;
    width: 100%;
}

.footer-socials{
    display: flex;
    gap: 14px;
}

.footer-socials-item{
    width: 52px;
    height: 52px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-social-icon{
    font-size: 24px;
    color: #262626;
}

.footer-copyright-ico{
    font-size: 19px;
}

.footer-copyright-block{
    display: flex;
    gap: 4px;
    padding-right: 15px;
}

@media only screen and (max-width: 910px) {
    .footer-contacts-item{
        font-size: 24px;
    }
    .footer-contacts{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .footer-links{
        flex-direction: column;
        gap: 20px;
        border: 0;
    }
    .footer-copyright-block{
        padding: 0;
    }
}
@media only screen and (max-width: 680px) {
    .footer{
        padding: 100px 10px 50px 10px;
    }
}
@media only screen and (max-width: 320px) {
    .footer-copyright-block{
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 14px;
    }
    .footer-contacts-item{
        font-size: 18px;
    }
}
@media only screen and (max-width: 260px) {}