.navbar{
    max-width: 1795px;
    height: 95px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 20px 35px;
    border-radius: 100px;
    border: 1px solid #26262650;
    margin-top: 50px;
}

.navbar-logo-container{
    display: flex;
    align-items: center;
}

.navbar-sign{
    max-width: 250px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
}


.navbar-btn{
    height: 55px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.navbar-backto{
    background-color: #000!important;
}
.navbar-backto:hover{
    background-color: #fff!important;
}

.app-logo-title{
    font-size: 20px;
}

@media only screen and (max-width: 1600px) {
    .navbar{
        margin: 0 20px;
        margin-top: 30px;
    }
}
@media only screen and (max-width: 1280px) {}
@media only screen and (max-width: 1280px) {}
@media only screen and (max-width: 1080px) {}
@media only screen and (max-width: 720px) {
    .navbar-btn{
        height: 55px;
        width: 100px;
        font-size: 12px;
        text-align: center;
    }
}
@media only screen and (max-width: 510px) {
    .navbar-sign{
        max-width: inherit;
        border-radius: 100px;
        display: flex;
        justify-content: space-between;
        gap: 5px;
        align-items: center;
    }
    .navbar-btn-ico{
        font-size: 20px!important;
    }
    .mob-btn-txt{
        display: none!important;
    }
    .navbar-btn{
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
    }
    .navbar-backto{
        border-radius: 100px;
        width: 50px!important;
    }
    .app-logo-title{
        display: none;
    }
}
@media only screen and (max-width: 320px) {
    .mob-btn-txt-start{
        display: none!important;
    }
    .navbar-btn{
        border-radius: 100px;
        width: 50px!important;
    }
}
@media only screen and (max-width: 260px) {
    
    .navbar-btn{
        height: 35px;
        width: 35px!important;
    }
    .navbar-btn-ico{
        font-size: 14px!important;

    }
}