.introduction{
    margin:60px 40px ;
    margin-bottom: 0;
    border-top: 1px solid #8f8f8f57;
    border-radius: 30px;
    padding: 80px 40px;
}

.introduction2{
    color: #2626a0;
    padding: 0 8%;
    padding-bottom: 80px;
}

.introduction3{
    color: #2626a0;
    padding: 80px 8%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro_title h3{
    font-size: 2em;
    font-weight: 400;
}

.intro_cards_flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ant_card{
    max-width: 500px;
    margin-bottom: 60px;
    margin-right: 60px;
}

.intro_title h1{
    max-width: 1200px;
    font-size: 3.2em;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 54px;
    padding-bottom: 10px;
    margin-bottom: 40px;
}

.intro_cards h6{
    font-size: 1.5em;
}
.intro_cards h5{
    font-size: 1.5em;
    font-weight: 400;
}

.intro_cards p{
    font-size: 1.2em;
    margin-top: 10px;
    font-weight: 500;
}

.introduction2{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.intro_title{
    grid-area: first;
}

.intro_services{
    grid-area: second;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.introduction_list_block{
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-areas: "first second";
    align-items: start;
    justify-content: space-between;
}

.introduction3 .introduction_list_block{
    grid-template-columns: 1.5fr 1fr;
    grid-template-areas: "second first";
}

.introduction3 .intro_title{
    text-align: right;
}
.introduction_list{
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
}

.introduction_list li{
    list-style-type: none;
    padding: 15px 0;
    border-bottom: 1px solid navy;
    font-size: 1.8em;
    font-weight: 300;
}

.intro_title h2{
    font-weight: 400;
    font-size: 2em;
}

.intro_services h1{
    line-height: 45px;
    font-size: 2em;
    font-weight: 400;
}

.all_services{
    margin-top: 80px;
    display: inline-block;
    border: 1px solid navy;
    padding: 10px 25px;
    border-radius: 5px;
    font-size: 1.3em;
    transition: all .4s ease-in;
}

.all_services:hover{
    color: #fff;
    background-color: #2626a0;
    cursor: pointer;
}

/* DEVIDER */

.text_devider{
    color: white;
    font-size: 36px;
    font-weight: 400;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}
.scroll {
    display: flex;
    background: black;
}
.scroll .text {
    white-space: nowrap;
    text-transform: uppercase;
    animation: scroll 40s linear infinite;
}
.scroll .text:nth-child(2) {
    animation: scroll2 40s linear infinite;
    animation-delay: -20s;
}

.intro_title_img_2{
    justify-content: end;
}

.faq_section h2{
    text-align: center;
    font-size: 2.4em;
    font-weight: 500;
}

.ant_card br{
    display: none;
}

@keyframes scroll {
    from {
        transform: translateX(100%);
   }
    to {
        transform: translateX(-100%);
   }
}
@keyframes scroll2 {
    from {
        transform: translateX(0%);
   }
    to {
        transform: translateX(-200%);
   }
}

@media (max-width: 1850px){
    .ant_card{
        max-width: 450px;
        margin-right: 50px;
    }
}
@media (max-width: 1730px){
    .intro_services{
        font-size: .9em;
    }

    .ant_card br{
        display: inherit;
    }
    .introduction_list li{
        font-size: 1.7em;
    }   
    .ant_card{
        max-width: 400px;
        margin-right: 50px;
    }
}
@media (max-width: 1530px){
    .intro_title h1{
        font-size: 3em;
    }
    .ant_card{
        max-width: 350px;
        margin-right: 50px;
    }
    .introduction_list li{
        font-size: 1.5em;
    }   
}

@media (max-width: 1390px){
    .ant_card br{
        display: none;
    }
    .intro_title_img{
        display: flex;
    }    
    .intro_title_img img{
        display: block;
    }
    .intro_cards_flex{
        justify-content: center;
    }
    .ant_card{
        max-width: 420px;
        margin-right: 40px;
    }
    .introduction_list li{
        font-size: 1.4em;
    }  
}
@media (max-width: 1280px){
    .ant_card{
        margin-right: 40px;
    }
    .intro_title h2{
        font-size: 1.7em;
    }
    .intro_services h1{
        font-size: 1.5em;
        line-height: 36px;
    }
    .introduction_list li{
        font-size: 1.2em;
    }  
}
    
@media (max-width: 1102px){
    .intro_cards h5{
        font-size: 1.8em;
    }
    .intro_cards p{
        font-size: 1.2em;
    }
    .intro_cards h6{
        font-size: 1.5em;
    }
    .introduction_list{
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        grid-gap: 0;
    }
    .intro_title_img img{
        margin-bottom: 40px;
    }
}
@media (max-width: 1090px){
    .intro_cards_flex{
        justify-content: space-around;
    }
    .intro_title_img{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .introduction_list_block{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .intro_title h2{
        text-align: left;
        margin-bottom: 30px;
    }
}
@media (max-width: 780px){
    .ant_card br{
        display: inherit;
    }
    .ant_card{
        max-width: 270px;
        margin: 20px auto;
    }
    .intro_title_img{
        margin-bottom: 30px;
    }
    .intro_services h1{
        font-size: 2em;
    }
    .intro_title h1{
        font-size: 2em;
    }
    .intro_title h3{
        font-size: 1.4em;
    }
    .ant_card h6{
        text-align: center;
    }
}
@media (max-width: 585px){
    .introduction{
        border-radius: 0;
        margin: 0;
        margin-top: 50px;
        padding: 50px 30px!important;
    }
    .introduction{
        padding: 0;
        padding-top: 50px;
    }
    .intro_cards_flex{
        justify-content: center;
    }
}